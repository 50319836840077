<template>
  <div>
    <h1 class="text-center mt-4 fw-normal">Comandas</h1>
    <div class="mt-4 ms-4 pb-2" id="categories-nav">
      <span class="btn rounded-5 px-3 me-3 shadow-sm" :class="{ 'active': JSON.stringify(category.id) == JSON.stringify(activeCategoryId)}" 
        v-for="(category, index) in categories" :key="index" @click="ChangeCategory(category)">
        {{category.name}}
      </span>
    </div>
    <div class="pb-1 mt-3 " id="orders-list">
      <div class="row m-0" style="width: 95vw !important;" v-if="this.FilterOrders().length == 0">
        <img class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 mx-auto" src="../assets/not_plates_founds.png">
        <h5 class="text-center mt-1">No hay comandas en este estado</h5>
      </div>
      <div id="order-card" v-for="order in this.FilterOrders()" :key="order.id" @click="selectOrder(order)">
          <div class="d-flex justify-content-between align-items-center">
              <span>{{ formatTime(order.date) }}</span>
              <span>{{ order.table_number }}</span>
              <span></span>
          </div>
          <hr/>
          <div class="row mt-3" style="height: 80px;">
            <div class="col-8 ml-5" id="plates-list">
              <div v-for="item in order.plates" :key="item.id">
                  <span>{{ item.amount }}x</span> {{ item.name }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end align-items-end pb-2">
              <div id="order-footer" :class="orderStateClass(order.state)">
                {{ order.state }}
              </div>
            </div>
          </div>
      </div>
    </div>
    <OrderExpanded @fetchOrders="fetchOrders"
      :visible="modalDisplay"
      :order="order"
      @close="modalDisplay = false"
    ></OrderExpanded>
    <SpinnerComponent id="spinner"></SpinnerComponent>
</div>
</template>

<script>
import OrderExpanded from '@/components/OrderExpanded.vue'
import SpinnerComponent from '../components/SpinnerComponent.vue'
import { mapState } from 'vuex';
import axios from 'axios';

export default {
data() {
  return {
      states:["PENDIENTE", "EN CURSO", "ENTREGADO", "PAGADO", "CANCELADO"],
      categories:[
        {
          id: [-1],
          name: "Todas"
        },
        {
          id: [0, 1],
          name: "PENDIENTE / EN CURSO"
        },
        {
          id: [0],
          name: "Pendientes"
        },
        {
          id: [1],
          name: "En curso"
        },
        {
          id: [2],
          name: "Entregadas"
        },
        {
          id: [3],
          name: "Pagadas"
        },
        {
          id: [4],
          name: "Canceladas"
        },
      ],
      orders: [],
      modalDisplay: false,
      order: null,
      activeCategoryId: [-1],
      first_charge: true
    };
  },
  components:{
    OrderExpanded,
    SpinnerComponent
  },
  computed: {
    ...mapState(['partner_token'])
  },
  methods: {
    ChangeCategory(category){
      this.activeCategoryId = category.id
    },
    formatTime(dateTime) {
      const parts = dateTime.split(' ');
      const timePart = parts[1];
      return timePart.split(':')[0] + ":" + timePart.split(':')[1];
    },
    FilterOrders(){
      let orders_filtered = []

      this.activeCategoryId.forEach(id => {
        orders_filtered = orders_filtered.concat(this.orders.filter(order => id == -1 || order.state === this.states[id]))
      });
      orders_filtered.sort((a, b) => a.table_number - b.table_number)
      return orders_filtered
    },
    selectOrder(order) {
      this.order = order;
      this.modalDisplay = true;
    },
    orderStateClass(state) {
      return {
        'pendiente': state === 'PENDIENTE',
        'en-curso': state === 'EN CURSO',
        'entregada': state === 'ENTREGADO',
        'pagada': state === 'PAGADO',
        'rechazada': state === 'CANCELADO'
      };
    },
    async fetchOrders() {
      const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
      const url = process.env.VUE_APP_API_URL+'/v1/api/app/ActiveOrders';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        let orders = response.data

        orders.forEach(order=>{
            order.table_number = order.table.number

            let min_state = null

            let plates_structure = []
            
            order.shopping_carts.forEach(shopping_cart=>{
                let plate_structure = {}
                plate_structure.shopping_cart_id = shopping_cart.id
                plate_structure.plate_id = shopping_cart.plate.id
                plate_structure.name = shopping_cart.plate.name
                plate_structure.description = shopping_cart.plate.description
                plate_structure.category=shopping_cart.plate.category
                plate_structure.photo = shopping_cart.plate.photo
                plate_structure.price = shopping_cart.plate.price
                plate_structure.amount = shopping_cart.amount
                plate_structure.comment = shopping_cart.comment
                plate_structure.steps = shopping_cart.plate.steps

                let i = 0
                plate_structure.steps.forEach(step=>{
                  if(shopping_cart.steps_selected.length > i){
                    step.plate_selected = shopping_cart.steps_selected[i].plate
                  }
                  i++
                })

                // Ordenos los estados de cada plato de mayor a menor
                shopping_cart.state_orders = shopping_cart.state_orders.sort((a, b) => {
                    return new Date(b.created_at)- new Date(a.created_at);
                });

                console.log(shopping_cart.state_orders)
                plate_structure.state = shopping_cart.state_orders[0].state
                console.log("nuevo state: "+ plate_structure.state)
                console.log(min_state)
                if (min_state == null || min_state[0] > plate_structure.state.sort){
                    min_state = [plate_structure.state.sort, plate_structure.state.name]
                }
                console.log(min_state)

                plate_structure.state = plate_structure.state.name

                plates_structure.push(plate_structure)
            })

            order.plates = plates_structure

            order.state = min_state[1]
        })
        let numero_pedidos_antes = this.orders.length
        this.orders = orders

        if (this.order != null){
          let order_selected = this.orders.find(item => item.id === this.order.id);
          if(order_selected){
            this.order = order_selected
          }
          else{
            this.modalDisplay = false
            this.order = null
          }
        }

        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }else{
          let veces_sonar = this.orders.length - numero_pedidos_antes
          console.log("veces_sonar:" + veces_sonar)
          let i = 0
          while(i<veces_sonar){
            this.playSound()
            await this.delay(1000);
            i++
          }
        }
      } catch (error) {
        // Maneja el error
        this.plates = []
        document.getElementById("spinner").classList.add("d-none")
        console.error('Error fetching orders:', error);
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    playSound() {
      // Carga el archivo de audio
      const audio = new Audio(require('@/assets/sonido_apple.mp3'));
      // Reproduce el audio
      audio.play().catch(error => console.log("Error reproduciendo el audio:", error));
    },
    startPolling() {
        this.fetchOrders();
        this.pollingInterval = setInterval(this.fetchOrders, 15000); // Cada 15 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
  },
  created() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
};
</script>

<style scoped>

hr {
  color: #F47700;
  margin: 0;
}

#orders-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  justify-items: center;
}

#order-card {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  width: 80%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

#plates-list {
  max-height: 90px; 
  overflow-y: auto;
}

#plates-list::-webkit-scrollbar {
  width: 2px;
}

#plates-list::-webkit-scrollbar-thumb {
  background-color: #f47600ad;
  border-radius: 10px;
}

#order-footer {
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
  font-weight: bold;
}

#order-footer.pendiente { color: red }
#order-footer.en-curso { color: #F47700 }
#order-footer.entregada { color: green }
#order-footer.pagada { color: rgb(54, 117, 252) }
#order-footer.rechazada { color: rgb(129, 129, 129) }

@media (min-width: 768px) and (max-width: 1023px) {
  #orders-list {
    grid-template-columns: 1fr 1fr;
  }
  #order-card { 
    width: 90%;
  }
  
}

@media (min-width: 1024px) {
  #orders-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #order-card { 
    width: 95%;
  }
  #categories-nav {
    justify-content: center !important;
  }
}

#categories-nav {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
  }

  #categories-nav span{
    background-color: #f1f5f5;
    font-size: 1rem;
  }

  #categories-nav .active{
    background-color: #F47700;
    color: white;
  }

  #categories-nav::-webkit-scrollbar{
    height: 2px;
  }

  #categories-nav::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }
</style>
